import React, { FunctionComponent } from "react"
import secondsIcon from "../images/seconds-pro-icon.svg"
import heavySetIcon from "../images/heavyset-icon.svg"
import Hero from "../components/hero"
import Button from "react-bootstrap/Button"
import { graphql, Link, PageProps } from "gatsby"
import AppStore from "../components/appstore"
import PlayStore from "../components/playstore"
import SEO from "../components/seo"
import Layout from "../layouts/layout"
import { fluidWithIdentifier } from "../helpers/image-helpers"
import { MetadataProps, SEOProps } from "../types/types"

const Home: FunctionComponent<PageProps<MetadataProps & SEOProps>> = ({
  data,
}) => {
  return (
    <Layout>
      <SEO
        title="Runloop · Developer of Seconds Interval Timer"
        description="Runloop is the developer of Seconds Interval Timer
            and HeavySet for iOS. Runloop is based in the United Kingdom."
        image={data.seoImage.childImageSharp.sizes.src}
        favIconSizes={[16, 32, 48, 96]}
        favIconTemplate="/images/runloop/favicon-%s.png"
      />
      <Hero
        fluidImage={fluidWithIdentifier("secondsHero", data)}
        logo={secondsIcon}
        logoAlt="Seconds Pro Icon"
        heading="Seconds Interval Timer"
        lead="The best interval timer app for HIIT, Tabata and circuit training
              workouts."
      >
        <p>
          <AppStore url={data.site.siteMetadata.urls.seconds.appStore} />{" "}
          <PlayStore url={data.site.siteMetadata.urls.seconds.googlePlay} />
        </p>
        <Link to="/seconds-interval-timer">
          <Button>Learn more about Seconds</Button>
        </Link>
      </Hero>
      <Hero
        fluidImage={fluidWithIdentifier("heavysetHero", data)}
        logo={heavySetIcon}
        logoAlt="HeavySet Icon"
        heading="HeavySet"
        lead="Workout tracker & gym log for bodybuilding and strength training."
      >
        <p>
          <AppStore url={data.site.siteMetadata.urls.heavyset.appStore} />
        </p>
        <Link to="/heavyset">
          <Button>Learn more about HeavySet</Button>
        </Link>
      </Hero>
    </Layout>
  )
}

export default Home

export const query = graphql`
  fragment fluidBackground on File {
    childImageSharp {
      fluid {
        ...GatsbyImageSharpFluid
      }
    }
  }
  query HomePageQuery {
    site {
      siteMetadata {
        title
        emails {
          privacy
          support
        }
        urls {
          support
          seconds {
            appStore
            googlePlay
          }
          heavyset {
            appStore
          }
        }
      }
    }
    seoImage: file(relativePath: { eq: "images/seconds/promo.jpg" }) {
      childImageSharp {
        sizes {
          src
        }
      }
    }
    secondsHero: file(
      relativePath: { eq: "images/seconds/seconds-background.jpg" }
    ) {
      ...fluidBackground
    }
    heavysetHero: file(
      relativePath: { eq: "images/heavyset/heavyset-background.jpg" }
    ) {
      ...fluidBackground
    }
  }
`
